:root {
  background-color: #282c35;
  font-family: "Crimson Text, serif";
  display: flex;
  justify-content: space-around;
}

.react-icon {
  color: "white";
}
